import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()

  return (
    <Layout {...props}>
      <Seo title='Acerca de CryptoGames' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Acerca de CryptoGames'
          subheader='CryptoGames es un blog para todos los apasionados de la tecnología blockchain, juegos y NFTs. CryptoGames se ocupa tanto de contar de manera rigurosa y con pasión la actualidad en el mundo blockchain y las criptomonedas, como de analizar en profundidad los principales lanzamientos y compararlos con otros similares. Lanzada en 2021, CryptoGames tiene como objetivo ser en el blog líder en Crypto Games en español, creando una comunidad de usuarios muy informados, influyentes y altamente participativos.'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
